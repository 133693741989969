<template>
    <div>
        <WaveDownBG>
            <slot name="navigation" />

            <div class="c w-xxl mt-xxl pt-xxl overflow-hidden">
                <div class="w-12 s:w-10 m:w-8 l:w-6 xl:w-4">
                    <IntroText :title="data.impactsteps_title_en" :text="data.impactsteps_text_en" />
                </div>
            </div>
        </WaveDownBG>
        <ImpactSteps v-if="data.foundation_impactsteps && data.foundation_impactsteps.length > 0" :data="data.foundation_impactsteps[0]" />

        <div class="c w-s my-xxl pb-xxl">
            <div v-if="typeof article.config_en === 'string'" class="richtext color-secondary leading-m l-up:pr-xl accentlink" v-html="article.config_en" />
        </div>
    </div>
</template>

<script>
import WaveDownBG from '@/components/bgs/WaveDownSmall.vue';
import ImpactSteps from '@/components//ImpactSteps.vue';
import { strings } from 'p5-helpers';

export default {
    name: 'Article',
    components: {
        WaveDownBG,
        ImpactSteps,
    },
    data() {
        return {
            article: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => vm.getData());
    },
    beforeRouteUpdate(to, from, next) {
        next();
        this.getData();
    },
    methods: {
        getData() {
            if (window.PRERENDER_INJECTED) {
                const routedata = window.PRERENDER_INJECTED.routeData[this.$route.path];
                if (routedata) {
                    this.processData(routedata);
                }
            } else {
                const url = strings.replaceBrackets(this.$route.meta.article.endpoint, this.$route.params);
                this.$store.dispatch('get', url).then(this.processData);
            }
        },

        processData(r) {
            this.article = r;

            let schemas = [];
            if (this.$route.meta.article.schema) {
                if (typeof this.$route.meta.article.schema === 'function') {
                    schemas = this.$route.meta.article.schema(r, this);
                } else {
                    schemas = this.$route.meta.article.schema;
                }
            }
            this.$store.commit('setMeta', {
                data: {
                    title: r.meta_title_en,
                    description: r.meta_description_en,
                    keywords: (Array.isArray(r.meta_keywords_en) ? r.meta_keywords_en : []).map((it) => it.name).join(', '),
                },
                schemas,
            });
        },
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>